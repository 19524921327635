import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import Reducer from '../src/reducers/CounterReducer.js';

const initialState = {
    counterData: 1
}
const store = createStore(Reducer, initialState, composeWithDevTools());

export default store;
// CounterAction.js
export const  increment = (counterData) => {
    return {
        type: 'INCREMENT',
        payload: counterData
    }
}
export const decrement = (counterData) => {
    return {
        type: 'DECREMENT',
        payload: counterData
    }
}

export const reset = () => {
    return {
        type: 'RESET',
    }
}