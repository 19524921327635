/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowRight,faHome,faChevronRight,faChevronLeft,faDesktop,faQuestionCircle,faBars ,faTimes,faLaptop,faLandmark, faChevronDown, faCheck, faArrowUp,faUserCircle, faSlidersH, faClock} from '@fortawesome/free-solid-svg-icons'
import {faApple,faAndroid} from '@fortawesome/free-brands-svg-icons'
import UIkit from 'uikit/dist/js/uikit'
import 'uikit/dist/css/uikit.css'
import '@sass/main.scss'
import { graphql } from 'gatsby'
library.add(faArrowRight,faHome,faChevronRight,faChevronLeft,faDesktop,faQuestionCircle,faBars,faTimes,faLaptop,faLandmark,faChevronDown,faCheck,faApple,faAndroid,faArrowUp,faUserCircle,faSlidersH,faClock)

export { default as wrapRootElement } from './src/ReduxWrapper';

