const initialState = { statebreadcrumb: {} };

const reducer = (state = initialState, action) => {
    switch (action.type) {
     
        case 'INCREMENT': 
        return {
            ...state,
            counterData: action.payload + 1
        }
        case 'DECREMENT': return {
            ...state,
            counterData: action.payload -1
        }
        case 'RESET' : return {
            ...state,
            counterData: 0
        }
        case 'CHANGEBREADCRUMB' : return {
            ...state,
            statebreadcrumb: action.payload
        }
        default: return state
    }
}
export default reducer;